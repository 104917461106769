import consultingApi from "./consulting";
import publicApi from "./public";
import studentApi from "./student";

export const api = {
  ...consultingApi,
  ...studentApi,
  ...publicApi,
};

export default api;
