import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { deleteCookie, getCookie, setCookie } from "cookies-next";

import applyCaseMiddleware from "axios-case-converter";
import { tResponse } from "types";
import { TokenRefreshResponse } from "./api/student.type";

export class ApiClient {
  axios: AxiosInstance;
  patchAxios: AxiosInstance;
  config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  constructor() {
    const baseURL = this.getBaseURL();

    this.axios = applyCaseMiddleware(
      axios.create({
        baseURL,
      })
    );

    this.patchAxios = axios.create({
      baseURL,
    });

    this.setupInterceptors();
  }
  private getBaseURL(): string {
    if (typeof window === "undefined") {
      // 서버 사이드에서는 개발 환경 URL을 사용
      return process.env.NODE_ENV === "development"
        ? "https://dev-api.y3c.kr"
        : "https://api.y3c.kr";
    }

    // 클라이언트 사이드에서는 호스트네임에 따라 URL 결정
    return window.location.hostname === "dev-consulting.yeboc.kr" ||
      window.location.hostname === "localhost"
      ? "https://dev-api.y3c.kr"
      : "https://api.y3c.kr";
  }

  private setupInterceptors() {
    this.axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const accessToken = getCookie("accessToken");
        console.log("accessToken", accessToken);
        if (accessToken) {
          config.headers.set("Authorization", `Bearer ${accessToken}`);
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    this.patchAxios.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const accessToken = getCookie("accessToken");
        if (accessToken) {
          config.headers.set("Authorization", `Bearer ${accessToken}`);
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    this.axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const refreshToken = getCookie("refreshToken");
            if (!refreshToken) {
              throw new Error("Refresh token not found");
            }
            const response = await this.postStudentAuthTokenRefresh(
              refreshToken as string
            );
            const { accessToken, refreshToken: newRefreshToken } = response;

            setCookie("accessToken", accessToken);
            setCookie("refreshToken", newRefreshToken);

            originalRequest.headers.set(
              "Authorization",
              `Bearer ${accessToken}`
            );
            return this.axios(originalRequest);
          } catch (refreshError) {
            console.error("Token refresh failed:", refreshError);
            deleteCookie("accessToken");
            deleteCookie("refreshToken");
            // 여기에 로그아웃 로직 추가 (예: 로그인 페이지로 리다이렉트)
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    this.patchAxios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const refreshToken = getCookie("refreshToken");
            if (!refreshToken) {
              throw new Error("Refresh token not found");
            }
            const response = await this.postStudentAuthTokenRefresh(
              refreshToken as string
            );
            const { accessToken, refreshToken: newRefreshToken } = response;

            setCookie("accessToken", accessToken);
            setCookie("refreshToken", newRefreshToken);

            originalRequest.headers.set(
              "Authorization",
              `Bearer ${accessToken}`
            );
            return this.axios(originalRequest);
          } catch (refreshError) {
            console.error("Token refresh failed:", refreshError);
            deleteCookie("accessToken");
            deleteCookie("refreshToken");
            // 여기에 로그아웃 로직 추가 (예: 로그인 페이지로 리다이렉트)
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  private async postStudentAuthTokenRefresh(
    refreshToken: string
  ): Promise<TokenRefreshResponse> {
    return this.newPost<TokenRefreshResponse>(
      `/student/auth/token/refresh`,
      { refreshToken },
      {
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
        },
      }
    );
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const res = await this.patchAxios.get<T>(url, { ...config });
    return res.data;
  }

  async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<tResponse<T>> {
    const res = await this.axios.post<tResponse<T>>(url, JSON.stringify(data), {
      ...this.config,
      ...config,
    });
    const result = res.data;
    result.status = res.status;
    return result;
  }

  async newPost<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const res = await this.axios.post<T>(url, JSON.stringify(data), {
      ...this.config,
      ...config,
    });
    const result = res.data;
    return result;
  }

  async delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<tResponse<T>> {
    const res = await this.axios.delete<tResponse<T>>(url, {
      ...this.config,
      ...config,
    });
    const result = res.data;
    result.status = res.status;
    return result;
  }

  async patch<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<tResponse<T>> {
    const res = await this.patchAxios.patch<tResponse<T>>(url, data, {
      ...this.config,
      ...config,
    });
    const result = res.data;
    result.status = res.status;
    return result;
  }
}

export const apiClient = new ApiClient();
