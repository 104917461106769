import axios, { AxiosInstance } from "axios";
import { ApiClient, apiClient } from "../apiClient";
import {
  tAnalysisStrengthResponse,
  tConsultingSubjectStatusResponse,
  tUserConsultingAggregateScoreRank,
  tUserConsultingAnalysis,
  tUserConsultingFitness,
  tUserConsultingFitnessRank,
  tUserConsultingSubjectScore,
  tUserStudyStyle,
} from "../apiType";

import applyCaseMiddleware from "axios-case-converter";
import { eUniversity } from "types/enums";

const url =
  process.env.NODE_ENV === "development" ||
  window.location.hostname === "dev-consulting.yeboc.kr"
    ? "https://0fz37jnnva.execute-api.ap-northeast-2.amazonaws.com"
    : "https://0fz37jnnva.execute-api.ap-northeast-2.amazonaws.com";

class API {
  private apiClient: ApiClient;
  axios: AxiosInstance;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
    this.axios = applyCaseMiddleware(
      axios.create({
        baseURL: url,
      })
    );
  }

  // 유저 프로필
  getUser = async (hash: string) => {
    return this.apiClient
      .get<any>(`/assessment/ssrt/${hash}/profile`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUser: ", err);
        throw err;
      });
  };

  // 결과설명 api (explanation, todolist)
  getUserConsultingAnalysis = async (hash: string, university: eUniversity) => {
    return this.apiClient
      .get<tUserConsultingAnalysis>(`/assessment/ssrt/${hash}/analysis`, {
        params: { university },
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUserConsultingAnalysis: ", err);
      });
  };

  // 공부성향 api
  getUserStudyStyle = async (hash: string) => {
    return this.apiClient
      .get<tUserStudyStyle>(`/assessment/ssrt/${hash}/study-style`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUserStudyStyle: ", err);
      });
  };

  // 적합도 apiz
  getUserConsultingFitness = async (hash: string) => {
    return this.apiClient
      .get<tUserConsultingFitness>(`/assessment/ssrt/${hash}/fitness`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUserConsultingFitness: ", err);
      });
  };

  // 적합도 랭킹 api
  getUserConsultingFitnessRank = async (
    hash: string,
    university: eUniversity,
    admissionType: "jungshi" | "sooshi"
  ) => {
    return this.apiClient
      .get<tUserConsultingFitnessRank>(
        `/assessment/ssrt/${hash}/fitness-rank`,
        {
          params: {
            university,
            admissionType,
          },
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .catch((err) => {
        console.error("err_getUserConsultingFitnessRank: ", err);
      });
  };

  // 총점 랭킹 api
  getUserConsultingAggregateScoreRank = async (
    hash: string,
    university: eUniversity
  ) => {
    return this.apiClient
      .get<tUserConsultingAggregateScoreRank>(
        `/assessment/ssrt/${hash}/aggregate-score-rank?university=${university}`,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .catch((err) => {
        console.error("err_getUserConsultingAggregateScoreRank: ", err);
      });
  };

  // 과목별 점수 api
  getUserConsultingByUniversity = async (
    hash: string,
    university: eUniversity
  ) => {
    return this.apiClient
      .get<tUserConsultingSubjectScore>(
        `/assessment/ssrt/${hash}/by-university`,
        {
          params: { university },
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .catch((err) => {
        console.error("err_getUserConsultingByUniversity: ", err);
      });
  };

  // 결과 설명 api (강점분석)
  // /api/consultings/{consulting_id}/analysis/strength
  getUserConsultingAnalysisStrength = async (
    hash: string,
    university: eUniversity
  ) => {
    return this.apiClient
      .get<tAnalysisStrengthResponse>(
        `/assessment/ssrt/${hash}/analysis/strength`,
        {
          params: { university },
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .catch((err) => {
        console.error("err_getUserConsultingAnalysisStrength: ", err);
      });
  };

  // 대학군 별 과목 상태 API
  // /api/consultings/{consulting_id}/subject-status-by-university
  getConsultingSubject = async (hash: string, university: eUniversity) => {
    return this.apiClient
      .get<tConsultingSubjectStatusResponse>(
        `/assessment/ssrt/${hash}/subject-status-by-university`,
        {
          params: { university },
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .catch((err) => {
        console.error("err_getConsultingSubjectStatus: ", err);
      });
  };

  getUserV0 = async (consulting_id: string) => {
    return this.axios
      .get<any>(`${url}/api/consultings/${consulting_id}/profile`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUser: ", err);
        throw err;
      });
  };

  // 결과설명 api (explanation, todolist)
  getUserConsultingAnalysisV0 = async (
    consulting_id: string,
    university: eUniversity
  ) => {
    return this.axios
      .get<any>(`${url}/api/consultings/${consulting_id}/analysis`, {
        params: { university },
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUserConsultingAnalysis: ", err);
      });
  };

  // 공부성향 api
  getUserStudyStyleV0 = async (consulting_id: string) => {
    return this.axios
      .get<any>(`${url}/api/consultings/${consulting_id}/study-style`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUserStudyStyle: ", err);
      });
  };

  // 적합도 api
  getUserConsultingFitnessV0 = async (consulting_id: string) => {
    return this.axios
      .get<any>(`${url}/api/consultings/${consulting_id}/fitness`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUserConsultingFitness: ", err);
      });
  };

  // 적합도 랭킹 api
  getUserConsultingFitnessRankV0 = async (
    consulting_id: string,
    university: eUniversity,
    admissionType: "jungshi" | "sooshi"
  ) => {
    return this.axios
      .get<any>(`${url}/api/consultings/${consulting_id}/fitness-rank`, {
        params: {
          university,
          admissionType,
        },
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUserConsultingFitnessRank: ", err);
      });
  };

  // 총점 랭킹 api
  getUserConsultingAggregateScoreRankV0 = async (
    consulting_id: string,
    university: eUniversity
  ) => {
    return this.axios
      .get<any>(
        `${url}/api/consultings/${consulting_id}/aggregate-score-rank?university=${university}`,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .catch((err) => {
        console.error("err_getUserConsultingAggregateScoreRank: ", err);
      });
  };

  // 과목별 점수 api
  getUserConsultingByUniversityV0 = async (
    consulting_id: string,
    university: eUniversity
  ) => {
    return this.axios
      .get<any>(
        `${url}/api/consultings/${consulting_id}/by-university?name=${university}`,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .catch((err) => {
        console.error("err_getUserConsultingByUniversity: ", err);
      });
  };

  // 결과 설명 api (강점분석)
  // /api/consultings/{consulting_id}/analysis/strength
  getUserConsultingAnalysisStrengthV0 = async (
    consulting_id: string,
    university: eUniversity
  ) => {
    return this.axios
      .get<any>(`${url}/api/consultings/${consulting_id}/analysis/strength`, {
        params: { university },
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getUserConsultingAnalysisStrength: ", err);
      });
  };

  getConsultingSubjectV0 = async (
    consulting_id: string,
    university: eUniversity
  ) => {
    return this.axios
      .get<any>(
        `${url}/api/consultings/${consulting_id}/subject-status-by-university`,
        {
          params: { name: university },
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .catch((err) => {
        console.error("err_getConsultingSubjectStatus: ", err);
      });
  };
}

const consultingApi = new API(apiClient);
export default consultingApi;
