import { ApiClient, apiClient } from "../apiClient";

import { ResetPasswordRequestResponse } from "./public.type";

class PublicAPI {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  getPublicEmailCheck = async (email: string) => {
    return this.apiClient
      .get<any>(`/public/email/check`, {
        params: { email },
      })
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  postPublicAuthResetPasswordRequest = async (data: { email: string }) => {
    return this.apiClient
      .post<ResetPasswordRequestResponse>(
        `/public/auth/reset-password/request`,
        data
      )
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  postPublicAuthResetPasswordVerify = async (data: any) => {
    return this.apiClient
      .post<any>(`/public/auth/reset-password/verify`, data)
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  // [Public] webhook
  getPublicWebhookList = async () => {
    return this.apiClient.get<any>(`/public/webhook/list`).catch((err) => {
      console.error("err_api: ", err);
      throw err;
    });
  };

  postPublicWebhookTypeformAssessment = async (data: any) => {
    return this.apiClient
      .post<any>(`/public/webhook/typeform/assessment`, data)
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  // [Public] Assessment
  getPublicAssessment = async () => {
    return this.apiClient.get<any>(`/public/assessment`).catch((err) => {
      console.error("err_api: ", err);
      throw err;
    });
  };
}

const publicApi = new PublicAPI(apiClient);
export default publicApi;
