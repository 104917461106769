import { ApiClient, apiClient } from "../apiClient";
import {
  ConsultantListResponse,
  CreateRequestData,
  DeleteAccountData,
  LoginData,
  LoginResponse,
  MyAssessmentResponse,
  ResetPasswordData,
  SignupData,
  SignupResponse,
  StudentMeProfileResponse,
  StudentRequestResponse,
  TokenRefreshResponse,
  TokenVerifyResponse,
  VoucherCheckResponse,
  VoucherUseResponse,
} from "./student.type";

class StudentAPI {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  // auth api
  postStudentAuthSignup = async (data: SignupData) => {
    return this.apiClient
      .post<SignupResponse>(`/student/auth/signup`, data)
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  postStudentAuthLogin = async (data: LoginData) => {
    return this.apiClient
      .post<LoginResponse>(`/student/auth/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  getStudentAuthTokenVerify = async (accessToken: string) => {
    console.log("getStudentAuthTokenVerify", accessToken);
    return this.apiClient
      .get<TokenVerifyResponse>(`/student/auth/token/verify`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch((err) => {
        throw err;
      });
  };

  postStudentAuthTokenRefresh = async (refreshToken: string) => {
    return this.apiClient
      .newPost<TokenRefreshResponse>(
        `/student/auth/token/refresh`,
        {
          refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
        }
      )
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  postStudentAuthResetPassword = async (data: ResetPasswordData) => {
    return this.apiClient
      .newPost<any>(`/student/auth/reset-password`, data, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  postStudentAuthDeleteAccount = async (data: DeleteAccountData) => {
    return this.apiClient
      .post<any>(`/student/auth/delete-account`, data)
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  // me api
  getStudentMeProfile = async () => {
    return this.apiClient
      .get<StudentMeProfileResponse>(`/student/me/profile`)
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  patchStudentMeProfile = async (data: any) => {
    return this.apiClient
      .patch<any>(`/student/me/profile`, data)
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  getStudentMeAssessment = async () => {
    return this.apiClient
      .get<MyAssessmentResponse>(`/student/me/assessment`)
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  postStudentMeAssessmentStart = async (userAssessmentId: number) => {
    return this.apiClient
      .post<any>(`/student/me/assessment/${userAssessmentId}/start`, {})
      .catch((err) => {
        console.error("err_api: ", err);
        throw err;
      });
  };

  postStudentVoucherUse = async (aliasCode: string) => {
    return this.apiClient
      .post<VoucherUseResponse>(
        `/student/voucher/use`,
        {
          aliasCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .catch((err) => {
        console.error("err_useVoucher: ", err);
        throw err;
      });
  };
  getStudentVoucherCheck = async (aliasCode: string) => {
    return this.apiClient
      .get<VoucherCheckResponse>(`/student/voucher/check/${aliasCode}`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .catch((err) => {
        console.error("err_getStudentVoucherCheck: ", err);
        throw err;
      });
  };

  // 학생이 자신의 요청 확인
  getStudentMeRequest = async () => {
    return this.apiClient
      .get<StudentRequestResponse>(`/student/me/request`)
      .catch((err) => {
        console.error("err_getStudentMeRequest: ", err);
        throw err;
      });
  };

  // 컨설턴트 리스트
  getStudentMeConsultant = async () => {
    return this.apiClient
      .get<ConsultantListResponse>(`/student/me/consultant`)
      .catch((err) => {
        console.error("err_getStudentMeConsultant: ", err);
        throw err;
      });
  };

  // 학생이 컨설팅 요청
  postStudentRequest = async (data: CreateRequestData) => {
    return this.apiClient
      .post<any>(
        `/student/request`,
        {},
        {
          params: {
            type: data.type,
          },
        }
      )
      .catch((err) => {
        console.error("err_postStudentRequest: ", err);
        throw err;
      });
  };
}

const studentApi = new StudentAPI(apiClient);
export default studentApi;
